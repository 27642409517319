import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import './group_radio.scss'



export default function RadioButtonsGroup({ content }: any) {
    return (
        <FormControl fullWidth className='filter-div'>
            <FormLabel id="demo-radio-buttons-group-label" className='label'>{content?.label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {content?.options?.map((item: any) => (
                    <FormControlLabel value={item?.value} control={<Radio className='radio-button'/>} label={item?.label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}