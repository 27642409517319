import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../State_Store/index' // Importing AppDispatch type for Thunks
import { getRequest } from '../../services'
import { useDispatch } from 'react-redux';


interface HomePageState {
    data: any[]
}

const initialState: HomePageState = {
    data: []
}

const HomePageSlice = createSlice({
    name: 'HomePage',
    initialState,
    reducers: {
        setHomePage(state, action: PayloadAction<any[]>) {
            state.data = action.payload  // Update state with action payload
        }
    }
})

// Export actions
export const { setHomePage } = HomePageSlice.actions
// Export reducer
export default HomePageSlice.reducer

function setTheme(theme: any) {
    Object.keys(theme).forEach(key => {
      // Convert snake_case to kebab-case for CSS variable names
      const cssVariableName = `--${key.replace(/_/g, '-')}`;
      document.documentElement.style.setProperty(cssVariableName, theme[key]);
    });
  }
// Thunk Function (Async Action Creator)
export const fetchHomePageData = () => async (dispatch: AppDispatch) => {
    try {
        // Example: fetching data from an API
        const response: any = await getRequest('stores/sub-domain?sub_domain=store')
        if (response.status) {
            dispatch(setHomePage(response.data))
            setTheme(response.data[0]?.store_design?.theme)
        } else {
            dispatch(setHomePage([]))
        }
    } catch (error) {
        console.error('Error fetching homepage data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}
export const useAppDispatch: () => AppDispatch = useDispatch;