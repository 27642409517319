import React, { ReactNode } from 'react';

import "./title.scss"


interface AppProps {
    children: string;
}

const Title: React.FC<AppProps> = ({ children }) => {
    return (
        <div className="Title-container">
            {children}
        </div>
    );
}

export default Title;