import Layout from "../../Component/Layout/index"
import SwiperComponent from "../../Component/Swiper";
import Description from "../../Component/Text/Description";
import Title from "../../Component/Text/Title";
import "./home.scss"
import NormalCart from "../../Component/Cart/NormalCart";
import ProductCart from "../../Component/Cart/ProductCart";
import CustomButton from "../../Component/Button";
import CustomSwiper from "../../Component/Swiper/custom_swiper";
import { useEffect } from "react";
import { fetchHomePageData, useAppDispatch } from "./controller";
import { useSelector } from 'react-redux'
import CustomerSupport from "../../Component/Footer/CustomerSupport";




function Home() {

    const dispatch = useAppDispatch();
    const homePageData = useSelector((state: any) => state.homePage.data)

    // useEffect function: ----------------------------------------------------------------->>
    useEffect(() => {
        dispatch(fetchHomePageData());
    }, [])
    return (
        <Layout>
            <div className="Home-Page">
                <SwiperComponent Content={homePageData[0]?.store_design?.carosel} />
                <div className="Home-Page-Content">
                    <div className="section">
                        <div className="section-information">
                            <Title>Browse The Range</Title>
                            <Description> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Description>
                        </div>
                        <div className="section-cart">
                            <NormalCart data={homePageData[0]?.categories ?? []} />
                        </div>
                        <div className="section-information">
                            <Title>Our Products</Title>
                        </div>
                        <div className="section-cart">
                            <ProductCart data={homePageData[0]?.products ?? []} />
                        </div>

                        <CustomButton lable={"Show more"} type="outlined" />

                    </div>
                </div>
                <div className="Home-Page-Options-Component">
                    <div className="options-cart slider-component">
                        <div className="section-component info-section">
                            <Title>50+ Beautiful rooms
                                inspiration</Title>
                            <Description> Our designer already made a lot of beautiful prototipe of rooms that inspire you.</Description>
                            <CustomButton lable={"Explore more"} type="contained" />
                        </div>
                        <div className="section-component">
                            <CustomSwiper Content={homePageData[0]?.store_design?.store_gallery} />
                        </div>
                    </div>
                </div>
                <div className="Home-Page-Customer-Support">
                    <CustomerSupport image={homePageData[0]?.store_design?.footer_image?.banner} />
                </div>
            </div>
        </Layout>
    );
}

export default Home;
