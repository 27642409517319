import React, { ReactNode } from 'react';
import ResponsiveAppBar from "../Header"
import "./pagelayout.scss"
import Footer from '../Footer/Footer';


interface AppProps {
  children: ReactNode;
}

const Layout: React.FC<AppProps> = ({ children }) => {
  return (
    <div className="Layout-container">
      <div className='Header-container'>
        <ResponsiveAppBar />
      </div>
      <div className='Body-container'>
        {children}
      </div>
      <div className='Footer-container'>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;