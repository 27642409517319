import { configureStore } from '@reduxjs/toolkit'
import HomePageReducer from '../pages/Home/controller';

const store = configureStore({
  reducer: {
    homePage: HomePageReducer,
  },
  // Redux Toolkit automatically includes redux-thunk, so no need to add it separately
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
