import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/index";
import ProductPage from './pages/Products';
import CategoryPage from './pages/Category';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Category" element={<CategoryPage />} />
        <Route path="/products/:category_slug" element={<ProductPage />} />
      </Routes>
    </div>
  );
}

export default App;
