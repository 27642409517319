import Layout from "../../Component/Layout/index"
import SwiperComponent from "../../Component/Swiper";
import Description from "../../Component/Text/Description";
import Title from "../../Component/Text/Title";
import "./category.scss"
import NormalCart from "../../Component/Cart/NormalCart";
import ProductCart from "../../Component/Cart/ProductCart";
import CustomButton from "../../Component/Button";
import CustomSwiper from "../../Component/Swiper/custom_swiper";
import { useEffect } from "react";
import { fetchHomePageData, useAppDispatch } from "./controller";
import { useSelector } from 'react-redux'
import CustomerSupport from "../../Component/Footer/CustomerSupport";
import RadioButtonsGroup from "../../Component/Groupradio";
import CustomeSearchCheckbox from "../../Component/Checkbox_with_search";
import SizeSelector from "../../Component/Select_box_with_search";
import CustomeSelect from "../../Component/Select_Input";




function CategoryPage() {

    const dispatch = useAppDispatch();
    const homePageData = useSelector((state: any) => state.homePage.data)

    // useEffect function: ----------------------------------------------------------------->>
    useEffect(() => {
        dispatch(fetchHomePageData());
    }, [])

    const Radiodetails = {
        label: "Gender",
        options: [{ value: "kids", label: "Kids" }, { value: "men", label: "Men" }, { value: "women", label: "Women" }]
    }
    const categories = {
        label: "Categories", options: [
            "Red Plaid Shirt", "Blue Denim Jacket", "White Cotton T-Shirt", "Black Hoodie",
            "Gray Sweatpants", "Navy Chinos", "Olive Green Cargo Pants", "Yellow Polo Shirt",
            "Maroon Henley", "Striped Crewneck Sweater", "Graphic Print Tee", "Leather Jacket",
            "Khaki Shorts", "Black Skinny Jeans", "Light Wash Jeans", "Floral Print Shirt",
            "Brown Corduroy Pants", "V-Neck Sweater", "Camouflage Jacket", "Purple Fleece Hoodie",
            "Orange Track Pants", "Beige Trench Coat", "Distressed Jeans", "Green Flannel Shirt",
            "Heather Gray Tank Top", "Black Bomber Jacket", "Ripped Black Jeans", "White Sweatshirt",
            "Navy Blazer", "Graphic Hoodie", "Puffer Vest", "Cropped Sweatshirt", "Relaxed Fit Jeans",
            "High-Waisted Jeans", "Longline Cardigan", "Pastel Yellow Tee", "Striped Polo Shirt",
            "Checked Blazer", "Brown Leather Belt", "Slim Fit Dress Shirt", "Cargo Shorts",
            "Oversized Hoodie", "Button-Up Cardigan", "Blue Oxford Shirt", "Black Dress Pants",
            "White Linen Shirt", "Charcoal Joggers", "Orange Beanie", "Denim Overalls",
            "Brown Knit Sweater", "Olive Utility Jacket", "Gray Pullover", "Red Quarter-Zip",
            "Rugby Shirt", "Tie-Dye Tee", "Varsity Jacket", "Bomber Vest", "Fitted Dress Shirt",
            "Tan Chinos", "Short Sleeve Hoodie", "Double-Breasted Coat", "Cuffed Jeans",
            "Lightweight Windbreaker", "Striped Henley", "Mock Neck Sweater", "Cropped Tee",
            "Utility Cargo Pants", "Sherpa-Lined Jacket", "Graphic Long Sleeve", "Striped Hoodie",
            "Anorak Jacket", "Track Jacket", "Long Sleeve Polo", "Relaxed Fit Chinos", "Denim Jacket",
            "Knitted Sweater Vest", "Plaid Flannel Shirt", "Utility Vest", "Short Sleeve Sweater",
            "Corduroy Jacket", "Beige Puffer Jacket", "Camo Pants", "Embroidered Sweatshirt",
            "Overshirt", "Thermal Top", "Hiking Pants", "Wool Blazer", "Gray Lounge Pants"
        ]
    }
    const ageGroups = {
        label: "Age", options: [
            "8-9Y", "9-10Y", "11-12Y", "13-14Y",
            "4-5Y", "5-6Y", "6-7Y", "7-8Y",
            "10-11Y", "12-14Y", "2-3Y", "3-4Y"
        ]
    };
    const priceRanges = {
        label: "Prices", options: [
            { value: "299-798", label: "Rs. 299 to Rs. 798" },
            { value: "799-1298", label: "Rs. 799 to Rs. 1298" },
            { value: "1299-1798", label: "Rs. 1299 to Rs. 1798" },
            { value: "1799-2298", label: "Rs. 1799 to Rs. 2298" },
            { value: "2299-2798", label: "Rs. 2299 to Rs. 2798" },
            { value: "2799-3299", label: "Rs. 2799 to Rs. 3299" }
        ]
    };
    const sizes = {
        label: "Size", options: [
            "UK 7", "3-4Y", "28", "XS", "M", "34", "11-12Y", "4-5Y", "UK 6", "10-11Y",
            "L", "S", "36", "5-6Y", "7-8Y", "9-10Y", "UK 5", "6-7Y", "38", "XL",
            "12-13Y", "XXL", "8-9Y", "5", "UK 4", "7", "29", "39", "6"
        ]
    };
    const theme = {
        label: "Theme", options: [
            "Dc Theme"
        ]
    };
    const sortingOptions = [
        { value: "a-to-z", label: "A to Z" },
        { value: "price-high-to-low", label: "Price-High to Low" },
        { value: "price-low-to-high", label: "Price-Low to High" },
        { value: "newest", label: "Newest" },
        { value: "popularity", label: "Popularity" }
    ];


    return (
        <Layout>
            <div className="Category-Page">
                <img src={homePageData[0]?.store_design?.carosel?.images[0]?.banner} alt="category-banner" className="Category-banner" />
                <div className="Category-Page-Content">
                    <div className="Category-section">

                        <div className="Category-section-with-sort">
                            <div className="Category-Name-and-filter"> 
                                <div className="Category-section-information">
                                <Title>Home / DC Comics Official Merchandise</Title>
                                <Description> DC Comics Official Merchandise - 236 items</Description>
                            </div>
                                <div className="Categroy-Filter" >
                                    <CustomeSelect Content={sortingOptions} />
                            </div></div>

                        </div>

                        <div className="Category-flex-component">
                            <div className="Category-section-filter">
                                <RadioButtonsGroup content={Radiodetails} />
                                <CustomeSearchCheckbox categories={categories} />
                                <SizeSelector categories={sizes} />
                                <CustomeSearchCheckbox categories={ageGroups} />
                                <RadioButtonsGroup content={priceRanges} />
                                <CustomeSearchCheckbox categories={theme} />
                            </div>
                            <div className="Category-section-information">
                                <div className="Category-section-content">
                                    <ProductCart data={homePageData[0]?.products ?? []} />
                                </div>
                            </div>

                        </div>
                        


                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CategoryPage;
