import * as React from 'react';
import { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './checbox_with_search.scss'



export default function CustomeSearchCheckbox({ categories }: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);

  // Filter the categories based on the search term
  const filteredCategories = categories?.options.filter((item: any) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Determine how many items to show based on `showAll` state
  const itemsToShow = showAll ? filteredCategories : filteredCategories.slice(0, 10);

  return (
    <FormControl fullWidth className='filter-div'>
      <FormLabel className='label'>{categories?.label}</FormLabel>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={`Search for the ${categories?.label}`}
        size="small"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <FormGroup>
        {itemsToShow.map((item: any, index: any) => (
          <FormControlLabel
            key={index}
            control={<Checkbox className="checkbox-button peer"  />}
            label={item}
          />
        ))}
      </FormGroup>
      {filteredCategories.length > 10 && (
        <Button className='button' onClick={() => setShowAll(!showAll)}>
          {showAll ? "- Show Less" : `+ Show More (${filteredCategories?.length - 10} more)`}
        </Button>
      )}
    </FormControl>
  );
}
