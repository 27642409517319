import Layout from "../../Component/Layout/index"
import "./product.scss"





function ProductPage() {

    return (
        <Layout>
            <div className="Product-Page">
             "Product"
            </div>
        </Layout>
    );
}

export default ProductPage;
