import React from 'react';
import "./normalcart.scss";

// Define a type for the content items
interface ContentItem {
    image: any;
    category_name: string;
}

interface AppProps {
    data: ContentItem[]; // Use the defined type for data
}

const NormalCart: React.FC<AppProps> = ({ data }) => {

    return (
        <div className="NormalCart">
            {data.map((content, index) => (
                <div className="NormalCart-container" key={index}> {/* Add a unique key */}
                    <img src={content?.image?.banner || ""} alt={content.category_name} /> {/* Use alt text */}
                    <h2>{content.category_name}</h2>
                </div>
            ))}
        </div>
    );
}

export default NormalCart;
